import { useQuery } from "@apollo/react-hooks";
import { Button, Spin, Table } from "antd";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import React, { useContext, useState } from "react";
import { Query } from "react-apollo";
import { TabContext } from "../../../../../../Context/Tab";
import Items from "../../../../../../entities/items";
import Loader from "../../../../../../shared/components/Loader";
import { ManufacturersQueries } from "../../../../../../shared/graphql/queries";
import { ITEM } from "../../../../../../shared/graphql/queries/item";
import { StoreContext } from "../../../../../../shared/store";
import { SCREENS, TABS_ACTIONS } from "../../../../../../utils/constants";
import { numberFormatter } from "../../../../../../utils/formatter-functions";
import { format, priceAndDiscount } from "../../../../../../utils/helpers";
import { ItemWareHousesList } from "../../../../WishList/Items/List/view";
import AddToCart from "./AddToCart";

export default React.memo(({ id, ...props }) => {
  const { user = {} } = useContext(StoreContext);

  const variables = {
    where: {
      parts: {
        id,
      },
    },
  };
  const { dispatch } = useContext(TabContext);

  const { ARTICLE } = ManufacturersQueries;
  const Price = ({
    prices,
    discount,
    account,
    itemWareHouses,
    itemId,
    itemName,
    openNotification,
    clientCity,
    code,
  }) => {
    const { dispatch } = useContext(TabContext);
    const [quantity, setQuantity] = useState(1);
    const { price, formula, original } = priceAndDiscount({
      quantity,
      prices,
      discount,
      account,
    });
    return (
      <div
        className="div-horizontal center "
        style={{ justifyContent: "flex-end" }}
      >
        <p style={{ margin: 0, marginRight: "15px" }}>
          {numberFormatter(original, 2) + " Dh"}
        </p>

        <AddToCart
          itemWareHouses={itemWareHouses || []}
          itemId={itemId}
          itemName={itemName}
          openNotification={openNotification}
          clientCity={clientCity}
          quantity={quantity}
          setQuantity={setQuantity}
          code={code}
        ></AddToCart>
      </div>
    );
  };

  const Stock = ({ itemId, user }) => {
    const { data: itemQuery, loading: loadingItemQuery } = useQuery(ITEM, {
      // fetchPolicy: "network-only",
      variables: { where: { id: itemId } },
    });

    if (loadingItemQuery) return <Spin spinning={true} />;

    return (
      <div className="div-horizontal center center-justified">
        <ItemWareHousesList
          item={get(itemQuery, "item")}
          prices={get(itemQuery, "item.prices", [])}
          itemWareHouses={get(itemQuery, "item.itemWareHouses", [])}
          quantity={1}
        />
      </div>
    );
  };

  const columns = (props) => [
    {
      title: "Réference",
      dataIndex: "code",
      key: "code",
      render: (code) => (
        <Button
          onClick={() => {
            props.dispatch({
              type: TABS_ACTIONS.HISTORY_PUSH,
              payload: {
                screen: SCREENS.ARTICLES,
                props: {
                  search: {
                    reference: code,
                    type: "direct",
                  },
                },
              },
            });
          }}
        >
          {code}
        </Button>
      ),
    },

    {
      title: "Déscription",
      key: "description",
      render: (_, { name, addName }) => `${name} ${addName}`,
    },
    {
      title: "Les Attributs",
      dataIndex: "attributes",
      key: "attributes",
      render: (_, { attributes }) =>
        attributes.map(({ name, value }) => (
          <p>
            {name} : {value}
          </p>
        )),
    },
    {
      title: "Marques",
      dataIndex: "brand",
      key: "brand",
      render: ({ name }) => name,
    },
    {
      title: "Disponibilité",

      key: "stock",

      render: (article) => {
        const { code, brand } = article;
        return (
          <Items
            options={{ fetchPolicy: "network-only" }}
            filter={{ where: { catalogCode: code } }}
            NoData={() => null}
            Loading={() => <Spin />}
            View={Loader(({ data }) => {
              const item = data.find(
                (dataItem) =>
                  format(get(article, "code", "")) ===
                  format(get(dataItem, "catalogCode", ""))
              );

              return <Stock itemId={get(item, "id")} user={user} />;
            })}
          />
        );
      },
    },

    {
      title: "Prix de vente brut",
      key: "item",
      render: (article) => {
        const { code, brand } = article;
        const { openNotification, history, clientCity } = props;
        return (
          <Items
            options={{ fetchPolicy: "network-only" }}
            filter={{ where: { catalogCode: code } }}
            NoData={() => null}
            Loading={() => <Spin />}
            View={Loader(({ data }) => {
              const item = data.find(
                (dataItem) =>
                  format(get(article, "code", "")) ===
                  format(get(dataItem, "catalogCode", ""))
              );

              return (
                <Price
                  itemWareHouses={get(item, "itemWareHouses", []) || []}
                  itemId={get(item, "id")}
                  itemName={get(item, "name")}
                  openNotification={openNotification}
                  history={history}
                  clientCity={clientCity}
                  prices={get(item, "prices", [])}
                  discount={get(item, "discount", [])}
                  code={code}
                  {...props}
                ></Price>
              );
            })}
          />
        );
      },
    },
  ];

  return (
    <Query query={ARTICLE} variables={variables}>
      {({ loading, error, data }) => {
        if (loading) return <Spin></Spin>;
        if (error) return `Error! ${error.message}`;
        const articles = get(data, "article.data", []);

        console.log("parts", articles);

        if (isEmpty(articles))
          return (
            <h4 className="not-found-message">Aucune pièce n'a été trouvée </h4>
          );
        return (
          <Table
            bordered
            columns={columns({ ...props, dispatch })}
            dataSource={articles}
            scroll={{ y: "calc((100vh / 1) - 200px)" }}
          />
        );
      }}
    </Query>
  );
});
