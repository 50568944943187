import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/dynamique/logo-blanc.png";
import { PATHS } from "../../../../utils/constants";

const Logo = (props) => (
  <div className="logo">
    <Link to={PATHS.HOME}>
      <img src={ logo} alt="" />
    </Link>
  </div>
);

export default Logo;
